import axios from 'axios'
import {config} from "../config";

interface MyResponseData {
    code: number;
    // 其他属性...
}

console.log(process.env.NODE_ENV);
const baseURL = process.env.NODE_ENV === 'development' ? config.REACT_APP_DEVBASE : config.REACT_APP_BASE


const request = axios.create({
    baseURL: baseURL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000 * 5, // request timeout
})


request.interceptors.response.use(
    (response) => {
        const res: any = response.data as MyResponseData;
        return res
        // if (res.code === 200 || res.error_code === 0 || res.error === false) {
        //     return res.data
        // }
        // return Promise.reject(new Error(res.message || 'Error'))
    },
    (error) => {
        return Promise.reject(error)
    },
)

export default request
