import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
  Popover,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [hierarchy, setHierarchy] = useState<any>({
    key: "0",
    text: t('全部層級'),
  });

  const [teamDetail, setTeamDetail] = useState<any>(null);
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const hierarchyList = [
    { key: "0", text: t('全部層級') },
    { key: "1", text: "1" },
    { key: "2", text: "2" },
    { key: "3", text: "3" },
    { key: "4", text: "4" },
    { key: "5", text: "5" },
    { key: "6", text: "6" },
    { key: "7", text: "7" },
    { key: "8", text: "8" },
    { key: "9", text: "9" },
    { key: "10", text: "10" },
    { key: "11", text: "11" },
    { key: "12", text: "12" },
    { key: "13", text: "13" },
    { key: "14", text: "14" },
    { key: "15", text: "15" },
    { key: "16", text: "16" },
    { key: "17", text: "17" },
    { key: "18", text: "18" },
    { key: "19", text: "19" },
    { key: "20", text: "20" },
    { key: "21", text: "21" },
    { key: "22", text: "22" },
    { key: "23", text: "23" },
    { key: "24", text: "24" },
    { key: "25", text: "25" },
    { key: "26", text: "26" },
    { key: "27", text: "27" },
    { key: "28", text: "28" },
    { key: "29", text: "29" },
    { key: "30", text: "30" },
    { key: "31", text: "31" },
    { key: "32", text: "32" },
    { key: "33", text: "33" },
    { key: "34", text: "34" },
    { key: "35", text: "35" },
    { key: "36", text: "36" },
    { key: "37", text: "37" },
    { key: "38", text: "38" },
    { key: "39", text: "39" },
    { key: "40", text: "40" },
  ];

  const getTeamDetail = async () => {
    try {
      const data = {
        account: email || address,
        token: token,
        cenji: hierarchy.key,
      };
      const res = await request({
        method: "POST",
        url: "/api/index/teaminfoPage",
        data: data,
      });

      setTeamDetail(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTeamDetail();
  }, [hierarchy]);

  return (
    <>
      <div className="TeamDetail">
        <Header title={t('團隊詳情')} />
        <div className="TeamDetail__wrap">
          <div className="TeamDetail__card">
            <div className="flex__direction">
              <span className="card__title">{t('團隊总業績')}</span>
              <span className="card__value">${teamDetail?.teamtotal}USDT</span>
            </div>
            <div className="card__big flex__space__between">
              <div className="flex__direction">
                <span className="card__title">{t('大區業績')}</span>
                <span className="card__value">${teamDetail?.bigtotal}USDT</span>
              </div>
              <div className="flex__direction">
                <span className="card__title">{t('小區業績')}</span>
                <span className="card__value">
                  ${teamDetail?.smalltotal}USDT
                </span>
              </div>
            </div>
          </div>
          <Popover.Menu
            actions={hierarchyList.map((action) => ({
              ...action,
            }))}
            className="PopoverClassSet"
            onAction={(node) => setHierarchy(node)}
            placement="bottom-start"
            trigger="click"
          >
            <div className="Hierarchy__box">{hierarchy.text}</div>
          </Popover.Menu>
          {/* <Tabbars /> */}
          <div style={{overflowX: 'scroll'}} className="Team__bot">
            <table className="tableList__Box">
              <thead>
                <tr className="">
                  <th>{t('用戶地址')}</th>
                  <th>{t('當前等級')}</th>
                  <th>{t('租賃金額')}</th>
                  <th>{t('託管金額')}</th>
                  <th>{t('節點金額')}</th>
                  {/* <th>{t('昨日累計收益')}</th> */}
                </tr>
              </thead>
              <tbody>
                {teamDetail?.teaminfo.length > 0 &&
                  teamDetail?.teaminfo.map((item: any) => {
                    return (
                      <tr className="">
                        <th>{addressSub(item.address, 4, 4)}</th>
                        <th>{item.level}</th>
                        <th>{item.power}</th>
                        <th>{item.defiamount}</th>
                        <th>{item.powerincome}</th>
                        {/* <th>{item.defiincome}</th> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
