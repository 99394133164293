import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import dayjs from "dayjs";
const Index: React.FC = (props: any) => {
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [activeKey, setActiveKey] = useState("0");
  const [history, setHistory] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);
  const [btnLoadin, setBtnLoadin] = useState<any>(false);
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const Tabbars: React.FC = () => {
    const setRouteActive = (value: string) => {
      setActiveKey(value);
    };

    const tabs = [
      {
        key: "0",
        title: t("歷史收益"),
      },
      {
        key: "1",
        title: t("訂單詳情"),
      },
    ];

    return (
      <TabBar activeKey={activeKey} onChange={(value) => setRouteActive(value)}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} title={item.title} />
        ))}
      </TabBar>
    );
  };

  const getPowerIncomeDetailPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/powerIncomeDetailPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setHistory(res?.data?.history);
      setOrder(res?.data?.order);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };
  const onClickBtn = async (value: any) => {
    try {
      setBtnLoadin(true);
      const parmas = { account: email || address,
        token: token,  orderid: value };
      const res = await request({
        method: "POST",
        url: "/api/users/sellpower",
        params: { lang: i18n.language },
        data: parmas,
      });
      setBtnLoadin(false);
      getPowerIncomeDetailPage();
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  useEffect(() => {
    setActiveKey(location.state);
  }, [location]);

  useEffect(() => {
    getPowerIncomeDetailPage();
  }, [i18n.language]);

  return (
    <>
      <div className="Income">
        <Header title={t("收益明細")} />
        <Tabbars />
        <div className="Team__bot">
          {activeKey === "0" && (
            <div style={{overflowX: 'scroll'}}>
            <table className="tableList__Box">
              <thead>
                <tr className="">
                  <th>{t("時間")}</th>
                  <th>{t("租賃週期")}</th>
                  <th>{t("金額")}</th>
                </tr>
              </thead>
              <tbody>
                {history?.length > 0 &&
                  history.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="">
                        <th>
                          {dayjs.unix(item.createtime).format("YYYY-MM-DD")}
                        </th>
                        <th>{item.cycle}</th>
                        <th>{item.amount}USDT</th>
                      </tr>
                    );
                  })}
              </tbody>
            </table></div>
          )}
          {activeKey === "1" && (
            <div style={{overflowX: 'scroll'}}>
            <table className="tableList__Box">
              <thead>
                <tr className="">
                  <th>{t("時間")}</th>
                  <th>{t("訂單號")}</th>
                  <th>{t("算力租賃")}</th>
                  <th>{t("租賃週期")}</th>
                  <th>{t("狀態")}</th>
                </tr>
              </thead>
              <tbody>
                {order?.length > 0 &&
                  order.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="">
                        <th>
                          {dayjs.unix(item.createtime).format("YYYY-MM-DD")}
                        </th>
                        <th>{item.number}</th>
                        <th>{item.power}</th>
                        <th>{item.cycle}</th>
                        <th>
                          {item.status === 0 ? (
                            t("訂單結束")
                          ) : (
                            <Button
                              disabled={item.status === 1 ? true : false}
                              onClick={() => onClickBtn(item.id)}
                              loading={btnLoadin}
                              size="small"
                              shape="rounded"
                              className="green-color white-color"
                            >
                              {t("取消復投")}
                            </Button>
                          )}
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table> </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Index;
