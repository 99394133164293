import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import dayjs from "dayjs";
import { throttle } from "lodash";
const Index: React.FC = (props: any) => {
  const [form] = Form.useForm()
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [activeKey, setActiveKey] = useState("0");
  const [detail, setDetail] = useState<any>([]);
  const [aiDetaliPage, setAiDetaliPage] = useState<any>();
  const [btnLoadin, setBtnLoadin] = useState<any>(false);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const getAiDetaliPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/aiDetaliPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setDetail(res?.data?.detail);
      setAiDetaliPage(res?.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };
  const onClickBtn = async (value: any) => {
    try {
      setBtnLoadin(true);
      const parmas = { account: email || address,
        token: token, amount: value?.amount };
      const res = await request({
        method: "POST",
        url: "/api/users/sellai",
        params: { lang: i18n.language },
        data: parmas,
      });
      setBtnLoadin(false);
      getAiDetaliPage();
    } catch (error) {}
  };
  const ThrottledOnClickNodeBuy  = throttle(onClickBtn, 3000, { leading: true, trailing: false });

  useEffect(() => {
    getAiDetaliPage();
  }, [i18n.language]);

  return (
    <>
      <div className="ManageDetail">
        <Header title={t("收益明細")} />
        <div style={{overflowX: 'scroll'}} className="Team__bot">
          <table className="tableList__Box">
            <thead>
              <tr className="">
                <th>{t('時間')}</th>
                <th>{t('產品')}</th>
                <th>{t('金額')}</th>
              </tr>
            </thead>
            <tbody>
              {detail?.length > 0 &&
                detail.map((item: any, index: number) => {
                  return (
                    <tr key={index} className="">
                      <th>
                        {dayjs
                          .unix(item.createtime)
                          .format("YYYY-MM-DD")}
                      </th>
                      <th>{item.product}</th>
                      <th>{item.amount}USDT</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default Index;
