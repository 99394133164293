import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import rightBlack from "assets/images/rightBlack.png";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import useNavitateLink from "utils/useNavitateLink";
interface WrapTitleProps {
  children: React.ReactNode;
  noDetail?: boolean;
  title?: string;
  id?: string;
  url?: string;
  data?: any;
}
const Index: React.FC<WrapTitleProps> = ({ children, noDetail, title, url, data, id }) => {
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <div className="WrapTitle">
        <div className="WrapTitle__title" id={id || ''}>
          <div className="titleFont">{title}</div>
          {!noDetail && <div className="moreDetail">
            <span className="text__center" onClick={() => url && goToPage(url)}>{t('更多詳情')}</span>
            <Image className="rightBlack" src={rightBlack} width={6} />
          </div>}
        </div>
        {children}
      </div>
    </>
  );
};
export default Index;
