import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
  Slider,
  ProgressBar,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { LuckyWheel, LuckyGrid } from '@lucky-canvas/react'
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import blockImg from "assets/images/blockImg.png"
import usdtImg from "assets/images/usdtImg.png"
import thanks from "assets/images/thanks.png"
import manyU from "assets/images/manyU.png"
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";

interface LuckyWheelProps {
  play: any;
  stop: any;
}

const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  const blocks = [
            { padding: '10px', background: '#ffc27a' },
            { padding: '10px', background: '#ff4a4c' },
          ]
    
  // const blocks = [
  //   // {
  //   //   padding: '12px',
  //   //   imgs: [{
  //   //     src: blockImg,
  //   //     width: '35px',
  //   //     height: '35px'
  //   //   }]
  //   // },
  //   // {
  //   //   imgs: [{
  //   //     src: blockImg,
  //   //     width: '35px',
  //   //     height: '35px'
  //   //   }]
  //   // }
  // ]
  const [progress, setProgress] = useState<any>(0)
  const awards = [
    {
      fonts: [{ fontSize: 12,text: t('謝謝惠顧'), top: '20px' }],
      background: '#fff',
      imgs: [
        {
          src: thanks,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12 ,text: '0.1U', top: '20px' }],
      background: 'rgb(255,247,218)',
      imgs: [
        {
          src: usdtImg,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12,text: '0.3U', top: '20px' }],
      background: '#fff',
      imgs: [
        {
          src: usdtImg,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12,text: '0.5U', top: '20px' }],
      background: 'rgb(255,247,218)',
      imgs: [
        {
          src: usdtImg,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12,text: '1U', top: '20px' }],
      background: '#fff',
      imgs: [
        {
          src: usdtImg,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12,text: '30U', top: '20px' }],
      background: 'rgb(255,247,218)',
      imgs: [
        {
          src: manyU,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12,text: '50U', top: '20px' }],
      background: '#fff',
      imgs: [
        {
          src: manyU,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
    {
      fonts: [{ fontSize: 12,text: '100U', top: '20px' }],
      background: 'rgb(255,247,218)',
      imgs: [
        {
          src: manyU,
          top: 45,
          width: '35px',
          height: '35px'
        }
      ]
    },
  ]
  const [buttons] = useState([
    // { radius: '25%', background: '#617df2' },
    { radius: '15%', background: '#ffc27a' },
    {
      radius: '10%', background: '#ff4a4c',
      pointer: true,
    }
  ])
  const myLucky = useRef<LuckyWheelProps | null>(null);
  useEffect(() => {}, []);

  const onclickBtn = async() => {
    if (progress === 100) {
      myLucky?.current?.play()
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: 'POST',
        url: '/api/users/draw',
        params: { lang: i18n.language },
        data: parmas,
      })
      // console.log(res.data)
      setTimeout(() => {
        myLucky?.current?.stop(res?.data - 1)
        getDrawPage();
      }, 2500)
    }
  }

  const getDrawPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/drawPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setProgress(res.data.p);
      // let getAwards = []
      // const awardsObj = res?.data?.awards;
      // for( let key in awardsObj) {
      //   const obj = {
      //     fonts: [{ text: awardsObj[key], top: '20px' }],
      //     background: Number(key) % 2 !== 0 ? '#fff' : 'rgb(255,247,218)'
      //   }
      //   getAwards.push(obj)
      // }
      // const getAwards = res?.data?.awards?.map((item: any, index: any) => {return {
      //   fonts: [{ text: item }],
      //   background: index % 2 !== 0 ? '#fff' : 'rgb(255,247,218)'
      // }})
    } catch (error) {}
  };
  useEffect(() => {
    getDrawPage();
  }, [])


  return (
    <>
      <div className="Lottery">
        <Header title={t('抽獎')}/>
        <div className="Lottery__box">
            <div style={{margin: '70px 0'}} className="flex__space__center">
            <LuckyWheel
              ref={myLucky}
              width="300px"
              height="300px"
              blocks={blocks}
              prizes={awards}
              buttons={buttons}
              onEnd={(prize: any) => { // 抽奖结束会触发end回调
                console.log(prize);
                if ( prize.fonts[0].text === t('謝謝惠顧')) {
                  Modal.show({
                    closeOnMaskClick: true,
                    content: t('謝謝惠顧')
                  })
                  
                } else {
                  const textAlert =(/^(30U|50U|100u)$/i.test(prize.fonts[0].text)) ? t('託管券') : ''
                  Modal.alert({
                    closeOnMaskClick: true,
                    content: t('恭喜你抽到') + prize.fonts[0].text + textAlert
                  })
                }
                // alert('恭喜你抽到 ' + prize.fonts[0].text + ' 奖品')
              }}
            />
            </div>
            <div className="card__box padding20">
              <p>{t('能量棒需要6個小時才能裝滿，裝滿後您可以有一次機會轉動獎盤')}</p>
              <ProgressBar
                percent={progress}
                style={{
                  '--fill-color': '#1f9e73',
                }}
                text
              />
              <div onClick={() => {onclickBtn()}} className="Lottery__btn">
                {t('立即抽獎')}
              </div>
            </div>
        </div>
      </div>
    </>
  );
};
export default Index;
