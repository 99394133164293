import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { injected } from "../../utils/web3React";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { config } from "../../config";
import { Modal, Image, Mask, SpinLoading } from "antd-mobile";
import noConnect from "../../assets/images/728/noConnect.png";
import logoIcon from "../../assets/images/728/logoIcon.png";
import { addressSub } from "../../utils/tools";
import fox from "assets/images/fox.png";
import tp from "assets/images/tp.png";
import "./index.scss";
import { t } from "i18next";

const WalletConnect = () => {
  const { active, account, activate, deactivate } = useWeb3React();
  const [ visible, setVisible] = useState(false);
  const [ mskVisible, setMskVisible] = useState(false);

  const modalDom = document.getElementById('wallet__Modal');

  const connectWallet = () => {
    setMskVisible(true);
    login();
  };
  const noconnectWallet = () => {
    logout();
  };
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          const res = await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
    setMskVisible(false);
    setVisible(false);
  }, []);
  const logout = useCallback(() => {
    deactivate();
  }, []);

  


  useEffect(() =>{
    console.log(modalDom, '1111111111111111111111111111');
  }, [modalDom])

  return (
    <div id="wallet__Modal" className="Index__header">
      {/* <img className="headerBox__logo" src={logoIcon} alt="" /> */}
      <div className="headerBox__Btn">
        {active ? (
          <>
            <div
              // onClick={() =>
              //   setVisible(true)
              // }
              className="headerBox__Btn__noConnect"
            >
              {/* <img className="headerBox__noConnect__img" src={noConnect} alt="" /> */}
              <span className="headerBox__Btn__BSC">BSC</span>{" "}
              <span className="headerBox__Btn__noConnectSpan">
                {addressSub(account || "0x0000000", 4, 4)}
              </span>
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => setVisible(true)}
              className="headerBox__Btn__noConnect"
            >
              {t('連結錢包')}
            </div>
          </>
        )}
        {/*<button onClick={() => connectWallet()}>链接钱包</button>*/}
        {/*{active ? <span>Connected with <b>{account}</b></span> : <span>Not connected</span>}*/}
        {/*<button onClick={() => noconnectWallet()}>断开链接</button>*/}
      </div>
      <Modal
        closeOnMaskClick={true}
        visible={visible}
        getContainer={modalDom}
        content={<div className="wallet__Modal">
        {mskVisible && <div className="maskModal__dom"><SpinLoading style={{ '--size': '32px' }} /></div>}
        <div className="wallet__Modal__title">{t('連結錢包')}</div>
        <div className="wallet__Modal__link" onClick={() => connectWallet()}> <Image src={fox} width={28} /><span className="wallet__Modal__or">OR</span><Image src={tp} width={28} /> </div>
      </div>}
      />
        
    </div>
  );
};
export default WalletConnect;
