import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import dayjs from "dayjs";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const [walletPage, setWalletPage] = useState<any>(null);
  const [ funddetailPage, setFunddetailPage] = useState<any>();

  const getWalletPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/walletPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setWalletPage(res.data);
    } catch (error) {}
  };
  const getFunddetailPage =async () => {
    try {
      const parmas = { account: email || address,
        token: token,};
      const res = await request({
        method: 'POST',
        url: '/api/index/funddetailPage',
        params: {lang: i18n.language},
        data: parmas
      })
      setFunddetailPage(res.data);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getWalletPage();
    getFunddetailPage();
  }, []);

  return (
    <>
      <div className="Home">
        <Header title={t('資金明細')} />
        <div className="WalletNet__bot">
          <div style={{overflowX: 'scroll'}}><table className="tableList__Box">
              <thead>
                <tr>
                  <th>{t('時間')}</th>
                  <th>{t('訂單號')}</th>
                  <th>{t('週期')}</th>
                  <th>{t('金額')}</th>
                  <th>{t('備註')}</th>
                </tr>
              </thead>
              <tbody>
                {funddetailPage?.length > 0 &&
                  funddetailPage?.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <th>{dayjs.unix(item.createtime).format('YYYY-MM-DD')}</th>
                        <th>{item.number}</th>
                        <th>{item.cycle}</th>
                        <th>{item.amount}</th>
                        <th>{item.memo}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </table></div>
          
        </div>
        {/* <div style={{overflowX: 'scroll'}} className="WalletNet__bot">
          <table className="tableList__Box">
            <thead>
              <tr>
                <th>{t('時間')}</th>
                <th>{t('訂單號')}</th>
                <th>{t('週期')}</th>
                <th>{t('金額')}</th>
                <th>{t('狀態')}</th>
              </tr>
            </thead>
            <tbody>
              {walletPage?.orders?.length > 0 &&
                walletPage?.orders.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <th>
                        {dayjs.unix(item.createtime).format("YYYY-MM-DD")}
                      </th>
                      <th>{item.number}</th>
                      <th>{item.cycle}</th>
                      <th>{item.amount}</th>
                      <th>{item.status === "1" ? t('進行中') : t('結束')}</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};
export default Index;
