import React from "react";
import "./App.scss";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";

function App() {
  const { active, account, activate, deactivate } = useWeb3React();
  const noconnectWallet = () => {
    // logout()
  };
  const connectWallet = () => {
    // login();
  };
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
