import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, modalComponents } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [invitePage, setInvitePage] = useState<any>();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  // 

  const getInvitePage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/invitePage1",
        params: {lang: i18n.language},
        data: parmas,
      });
      setInvitePage(res.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  useEffect(() => {
    getInvitePage();
  }, []);

  return (
    <>
      <div className="AllAward">
        <Header title={t('邀請總獎勵')} />
        <div className="AllAward__wrap font14">
          <div className="AllAward__card">
            <div onClick={() => modalComponents(t("獎勵規則"),
            t("award"))} className="AllAward__absolute">{t("獎勵規則")}</div>
            <div className="mb4">{t('邀請收益獎勵')}</div>
            <div className="font__bold font24 mb16">${invitePage?.inviterewards}</div>
            <div className="mb4">{t('昨日獎勵')}</div>
            <div className="font__bold">+${invitePage?.yesterdayrewards}</div>
          </div>
          <div className="flex__space__between mt16">
            <div className="min__card">
              <div className="mb8">{t('邀請獎勵')}</div>
              <div className="font__bold font24 mb16">${invitePage?.directrewards}</div>
              <div className="mb8">{t('昨日獎勵')}</div>
              <div className="green2-color font__bold">+${invitePage?.yesterdaydirectrewards}</div>
            </div>
            <div className="min__card">
              <div className="mb8">{t('團隊獎勵')}</div>
              <div className="font__bold font24 mb16">${invitePage?.teamrewards}</div>
              <div className="mb8">{t('昨日獎勵')}</div>
              <div className="green2-color font__bold">+${invitePage?.yesterdayteamrewards}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
