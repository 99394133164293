import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
  Stepper,
  Slider,
  StepperRef,
} from "antd-mobile";
import {
  getBalance_bnb,
  getContract,
  simpleRpcProvider,
} from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, modalComponents } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  useLocation,
  useParams,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import BTCIcon from "assets/images/BTCIcon.png";
import markIcon from "assets/images/markIcon.png";
import usdtImg from "assets/images/usdtImg.png";
import midBottom from "assets/images/midBottom.png";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import EchartsLine from "components/EchartsLine";
import request from "utils/request";
import dayjs from "dayjs";
import { throttle } from "lodash";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const stepDomRef: React.RefObject<StepperRef> = useRef(null);
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate, library, chainId, connector } =
    useWeb3React();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [sliderValue, setSliderValue] = useState<any>(50);
  const [amount, setAmount] = useState<any>(14);
  const [amountStep, setAmountStep] = useState<any>(14);
  const [powerDetailPage, setPowerDetailPage] = useState<any>();
  const [balance, setBalance] = useState<any>(null);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const modalContent = (
    <>
      <div>
        <div className="text__center mb16">
          {t("系統錢包資金不足，是否前往儲值")}
        </div>
        <div
          onClick={() => {
            Modal.clear();
            goToPage("/deposit");
          }}
          className="modal__goDeposit"
        >
          {t("確定")}
        </div>
      </div>
    </>
  );

  const onChangeInput = (value: number) => {
    const newValue = Math.max(0, Math.floor(value));
    setAmount(newValue);
  };

  const clickLease = async () => {
    // /api/users/buypower
    try {
      if (balance < amount) {
        Modal.show({
          title: t("提示"),
          content: modalContent,
          closeOnMaskClick: true,
        });
        return;
      }
      // balance

      const parmas = {
        account: email || address,
        token: token, 
        powerid: id,
        amount: String(powerDetailPage?.amount || amount),
      };
      const res: any = await request({
        method: "POST",
        url: "/api/users/buypower",
        params: { lang: i18n.language },
        data: parmas,
      });

      if (res.code === 1) {
        Toast.show(t("操作成功"));
      }
    } catch (error) {}
  };

  const ThrottledOnClickNodeBuy = throttle(clickLease, 3000, {
    leading: true,
    trailing: false,
  });

  const focusStep = () => {
    if (stepDomRef && stepDomRef.current) {
      stepDomRef.current?.focus();
    }
  };
  // /api/index/powerDetailPage

  const getPowerDetailPage = async () => {
    try {
      const parmas = {
        account: email || address,
        token: token, 
        powerid: id,
        amount: amount.toString(),
      };
      const res = await request({
        method: "POST",
        url: "/api/index/powerDetailPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setPowerDetailPage(res.data);

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  const getBalance = async () => {
    try {
      const parmas = { account: email || address,
        token: token,  };
      const res = await request({
        method: "POST",
        url: "/api/index/walletPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setBalance(res.data.balance);
    } catch (error) {}
  };

  useEffect(() => {
    getPowerDetailPage();
  }, [amount, i18n.language]);

  useEffect(() => {
    setSliderValue(Math.floor((powerDetailPage?.Day?.length - 1) / 2));
  }, [powerDetailPage]);

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <>
      <div className="Lease">
        <Header title={t("算力租賃")} />
        <div className="Lease__box">
          <div className="Lease__card">
            <div className="aling__center mb8">
              <span className="mr4">{t("套餐週期")}</span>
              <span className="font20 font__bold">
                {powerDetailPage?.cycle} <span>{t("日")}</span>
              </span>
            </div>
            <div className="mb8">
              <div>{t("挖礦時間")}</div>
              <div className="font__bold font16 mt2">
                {powerDetailPage?.starttime} ~ {powerDetailPage?.endtime}
              </div>
            </div>
            <div>
              <div>{t("收益發放")}</div>
              <div className="mt2 font__bold6">
                {/*{t(*/}
                {/*  "租賃成功，T+0日後每日24點(UTC+8)發放當日收益，收益直接發放至AI複合交易"*/}
                {/*)}*/}
                {t('每日返利，到期返本')}
              </div>
            </div>
            <Image
              className="BTCIconBox"
              src={BTCIcon}
              width={50}
              height={50}
            />
          </div>
        </div>
        <div className="Lease__bot">
          <div className="card__box">
            <div className="font__bold font20 mb16">{t("算力數額")}</div>
            <div className="Stepperbox">
              <span className="T">T</span>
              <Stepper
                ref={stepDomRef}
                value={amount}
                digits={0}
                onChange={onChangeInput}
                style={{
                  "--border": "1px solid #f5f5f5",
                  "--button-text-color": "#000",
                  "--button-font-size": "16px",
                  "--border-inner": "none",
                  "--button-width": "48px",
                  "--input-width": "calc(100% - 176)",
                  "--height": "48px",
                  "--button-background-color": "#FFFFFF",
                  "--input-background-color": "#F6F6F6",
                  "--active-border": "1px solid #1677ff",
                }}
                min={0}
                step={14}
              />
            </div>
            <div className="StepperNum">
              <div onClick={() => setAmount(14)} className="NumBtn">
                14
              </div>
              <div onClick={() => setAmount(70)} className="NumBtn">
                70
              </div>
              <div onClick={() => setAmount(140)} className="NumBtn">
                140
              </div>
              <div onClick={() => setAmount(280)} className="NumBtn">
                280
              </div>
              <div onClick={() => focusStep()} className="NumBtn">
                {t("自定義")}
              </div>
              <div onClick={() => modalComponents(
                        t("提示"),
                        t(
                          "單次租赁的算力数需要為14T的整数倍,單賬戶最大租赁7000T算力"
                        )
                      )
                    } className="NumBtn btndetail">
                {t("詳情說明")}
                <Image src={markIcon} width={20} height={20} />
              </div>
            </div>
          </div>
          <div className="card__box paddingb20">
            <div className="font__bold font20 mb16">{t("支付方式")}</div>
            <div className="aling__center">
              <Image src={usdtImg} width={24} height={24} />
              <span className="font__bold font16 ml8">USDT</span>
            </div>
            <div className="mt32">
              <div className="font__bold font20 mb16">{t("總計")}</div>
              <div className="mt16 aling__center flex__space__between padding016 font16">
                <span>{t("質押本金")}</span>
                <span className="font__bold">
                  {powerDetailPage?.usdtamount}USDT
                </span>
              </div>
              <div className="mt16 aling__center flex__space__between padding016 font16">
                <span>{t("到期返還")}</span>
                <span className="font__bold">
                  {powerDetailPage?.returnaomunt}USDT
                </span>
              </div>
            </div>
          </div>
          <div className="card__box paddingb20">
            <div className="font__bold font20 mb16">{t("免責聲明")}</div>
            {!showDisclaimer && (
              <div className="font136-color text__hidden">{t("ccf1")}</div>
            )}
            {showDisclaimer && (
              <div className="font136-color">
                <div className="mb8">{t("ccf1")}</div>
                <div className="mb8">{t("ccf2")}</div>
                <div className="mb8">{t("ccf3")}</div>
                <div className="mb8">{t("ccf4")}</div>
                <div className="mb8">{t("ccf5")}</div>
              </div>
            )}
            <div
              onClick={() => setShowDisclaimer(!showDisclaimer)}
              className="flex__space__center"
            >
              <Image
                className={showDisclaimer ? "transition180" : "transition0"}
                src={midBottom}
                width={22}
                height={13}
              />
            </div>
          </div>
          <div className="card__box paddingb20">
            <div className="font__bold font20 mb16">{t("預期收益")}</div>
            <div className="flex__space__between font14">
              <div>
                {t("到期礦機折損率")}：
                <span className="yellow-color">
                  {powerDetailPage?.damagerate}%
                </span>{" "}
              </div>
              <div>
                {t("礦機折損成本")}：
                <span className="yellow-color font__bold">
                  {powerDetailPage?.damagecost}USDT
                </span>
              </div>
            </div>
            <div className="flex__space__between font14">
              <div>
                {t("預估收益")}：
                <span className="green2-color font__bold">
                  {powerDetailPage?.incocme}USDT
                </span>{" "}
              </div>
              <div className="Income__center">
                {t("預估收益率")}：
                <span className="green2-color font__bold">
                  {powerDetailPage?.incomerate}USDT
                </span>
                <Image  onClick={() => modalComponents(
                        t("提示"),
                        t(
                          "當前收益率按照過往30日比特幣的價格、挖礦收益进行预估，不作為實隙收益保障"
                        )
                      )
                    } src={markIcon} width={20} height={20} />
              </div>
            </div>
            {powerDetailPage && (
              <EchartsLine
                powerproducts={powerDetailPage}
                sliderValue={sliderValue}
              />
            )}
            <Slider
              // defaultValue={40}
              className="Slider__name"
              onChange={setSliderValue}
              min={0}
              max={powerDetailPage?.Day?.length - 1 || 0}
              value={sliderValue}
              // onAfterChange={toastValue}
              icon={<span className="Slider__icon"></span>}
            />
            <div className="flex__space__between">
              <div>
                <span className="font__bold">
                  {powerDetailPage?.Day[sliderValue]} <span>{t("日")}</span>
                </span>
              </div>
              <div>
                <span className="font__bold">{t("算力收益")}：</span>
                <span className="green2-color">
                  {powerDetailPage?.Balance[sliderValue]}USDT
                </span>
              </div>
            </div>
            <div
              onClick={() => ThrottledOnClickNodeBuy()}
              className="Lease__nowbtn"
            >
              {t("立即租賃")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
