import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  useLocation,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import dayjs from "dayjs";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  let location = useLocation();
  const [announcement, setAnnouncement] = useState(location.state);
  console.log("params:", location.state);

  useEffect(() => {}, []);

  return (
    <>
      <div className="Announcement__box">
        <div className="Announcement__list">
          <div className="Announcement__title">
            {announcement.title}
          </div>
          <div className="Announcement__time break__spaces">
            {announcement.text}
          </div>
          <span className="Announcement__time">
            {dayjs.unix(announcement.createtime).format("YYYY-MM-DD")}
          </span>
        </div>
      </div>
    </>
  );
};
export default Index;
