import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
  Routes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import dayjs from "dayjs";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const location = useLocation();

  const [operationPage, setOperationPage] = useState<any>();
  const [exchangePage, setExchangePage] = useState<any>();
  const [btnLoadin, setBtnLoadin] = useState<any>(false);
  const { pathname } = location;
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const getOperationPage = async () => {
    try {
      const parmas = { account: email || address, token: token };
      const res = await request({
        method: "POST",
        url: "/api/index/operationPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setOperationPage(res.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };
  const getExchangePage = async () => {
    try {
      const parmas = { account: email || address, token: token };
      const res = await request({
        method: "POST",
        url: "/api/index/exchangePage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setExchangePage(res.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  const onClickBtn = async (value: any) => {
    try {
      setBtnLoadin(true);
      const parmas = { account: email || address, ticketid: value, token: token };
      const res: any = await request({
        method: "POST",
        url: "/api/users/useticket",
        params: { lang: i18n.language },
        data: parmas,
      });
      if (res.code === 1) {
        Toast.show(t("操作成功"));
      }
      setBtnLoadin(false);
      getExchangePage();
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  const Tabbars: React.FC = () => {
    const setRouteActive = (value: string) => {
      goToPage(value);
    };

    const tabs = [
      {
        key: "/actionRecord/0",
        title: t("儲值"),
      },
      {
        key: "/actionRecord/1",
        title: t("提現"),
      },
      {
        key: "/actionRecord/2",
        title: t("兌換"),
      },
      // {
      //   key: '/actionRecord/3',
      //   title: '我的',
      // },
    ];

    return (
      <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} title={item.title} />
        ))}
      </TabBar>
    );
  };

  function OperationPage() {
    return (
      <div style={{overflowX: 'scroll'}}><table className="tableList__Box">
        <thead>
          <tr className="">
            <th>{t("時間")}</th>
            <th>{t("金額")}</th>
            <th>{t("狀態")}</th>
          </tr>
        </thead>
        <tbody>
          {operationPage?.rechargedata?.length > 0 &&
            operationPage?.rechargedata?.map((item: any, index: number) => {
              return (
                <tr key={index} className="">
                  <th>{dayjs.unix(item.createtime).format("YYYY-MM-DD")}</th>
                  <th>{item.amount}USDT</th>
                  <th>{item.status === "1" ? t("儲值成功") : ""}</th>
                </tr>
              );
            })}
        </tbody>
      </table></div>
      
    );
  }

  function Withdrawdata() {
    return (
      <div style={{overflowX: 'scroll'}}><table className="tableList__Box">
        <thead>
          <tr className="">
            <th>{t("時間")}</th>
            <th>{t("金額")}</th>
            <th>{t("狀態")}</th>
          </tr>
        </thead>
        <tbody>
          {operationPage?.withdrawdata?.length > 0 &&
            operationPage?.withdrawdata?.map((item: any, index: number) => {
              return (
                <tr key={index} className="">
                  <th>{dayjs.unix(item.createtime).format("YYYY-MM-DD")}</th>
                  <th>{item.amount}USDT</th>
                  <th>
                    {item.status === "0"
                      ? t("处理中")
                      : item.status === "1"
                      ? t("提現成功")
                      : t("提現駁回")}
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table></div>
      
    );
  }
  function Exchange() {
    return (
      <div style={{overflowX: 'scroll'}}><table className="tableList__Box">
        <thead>
          <tr className="">
            <th>{t("兌換券")}</th>
            <th>{t("兌換券週期")}</th>
            <th>{t("兌換券利率")}</th>
            <th>{t("金額")}</th>
            <th>{t("狀態")}</th>
          </tr>
        </thead>
        <tbody>
          {exchangePage?.tickets?.length > 0 &&
            exchangePage?.tickets?.map((item: any, index: number) => {
              return (
                <tr key={index} className="">
                  <th>{item.name}</th>
                  <th>{item.cycle}</th>
                  <th>{item.interestrate}</th>
                  <th>{item.amount}</th>
                  <th>
                    {item.status === "0" ? (
                      t("已使用")
                    ) : (
                      <Button
                        onClick={() => onClickBtn(item.id)}
                        loading={btnLoadin}
                        size="small"
                        shape="rounded"
                        className="green-color white-color"
                      >
                        {t("兌換")}
                      </Button>
                    )}
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table></div>
      
    );
  }

  useEffect(() => {
    getOperationPage();
    getExchangePage();
  }, []);

  return (
    <>
      <div className="ActionRecord">
        <Header title={t("操作記錄")} />
        <Tabbars />
        {pathname === "/actionRecord/0" && (
          <div>
            <OperationPage />
          </div>
        )}
        {pathname === "/actionRecord/1" && (
          <div>
            <Withdrawdata />
          </div>
        )}

        {pathname === "/actionRecord/2" && (
          <div>
            <Exchange />
          </div>
        )}
      </div>
    </>
  );
};
export default withRouter(Index);
