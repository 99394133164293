import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, toWei } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import usdtImg from "assets/images/usdtImg.png";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate, library } = useWeb3React();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rechargeAd, setRechargeAd] = useState('');
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  const [form] = Form.useForm()

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const onFinish = async(a: any) => {
    try {
      if (!account) {
        Toast.show(t('請先登入錢包！'))
        return};

      setLoading(true)
      const numAmount = Number(a.amount)
      console.log(toWei(numAmount, config.REACT_APP_DECIMAL))
      const cutpayment = getContract(config.REACT_APP_USDT, config.REACT_APP_USDT_ABI,library)
      let balance = trim(await cutpayment.balanceOf(account), config.REACT_APP_DECIMAL)
      if (balance < numAmount) {
        Toast.show(t('餘額不足'));
        return
      }
      let tx = await cutpayment.transfer(rechargeAd, toWei(numAmount, config.REACT_APP_DECIMAL))
      console.log('tx',tx)
      tx = await tx.wait()
      console.log('tx2',tx)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
    // await
  } 

  const getRechargeAd = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/rechargeAd",
        params: { lang: i18n.language },
        data: parmas,
      });

      setRechargeAd(res.data);
    } catch (error) {}
  };

  // 

  useEffect(() => {
    getRechargeAd();
    form.setFieldValue('amount', 0)
  }, []);

  return (
    <>
      <div className="OnlineRecharge">
        <Header title={t('儲值')} />
        <div className="OnlineRecharge__box">
          <div className="list__left">
            <Image src={usdtImg} width={40} height={40} />{" "}
            <div className="flex__space__between">
              <div className="flex__direction">
                <span className="USDT">USDT</span> <span>Tether</span>
              </div>
            </div>
          </div>
          <div className="card__box">
            <div>{t('儲值方式')}</div>
            <div className="card__box__input">USDT-BEP20</div>
          </div>
          <div className="card__box">
            <div>{t('儲值金額')}</div>
            <div className="card__box__input">
              <Form
                form={form}
                onFinish={onFinish}
                footer={
                  <Button
                    loading={loading}
                    style={{
                      '--background-color': 'rgb(36, 105, 82)'
                    }}
                  block type='submit' size='large'>
                    {t('儲值')}
                  </Button>
                }
                layout="horizontal">
                <Form.Item name={'amount'} extra={<div className={"form__USDT"}>USDT</div>}>
                  <Input />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
