// useNavigation.js
import withRouter from 'components/withRouter';
import { useNavigate, useLocation } from 'react-router-dom';

const useNavigation = () => {
  const navigate = useNavigate();
  const loaction = useLocation();

  const goToPage = (path: string, state?: any) => {
    if (loaction.search) {
      navigate(path + loaction.search, state)
    } else {
      navigate(path, state)
    }
  };

  return goToPage;
};

export default useNavigation;



