import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();

  useEffect(() => {}, []);

  return (
    <>
      <div className="AboutUs">
        <Header title={t('關於我們')} />
        <div className="padding20">
          <div className="font__bold mt8 font18">{t('比特大陸香港有限公司')}</div>
          <p>
            {t('bit1')}
          </p>
          <p>
            {t('bit2')}
          </p>
          <div className="font__bold mt8 font18">{t('bit5')}</div>
          <p>
            {t('bit3')}
          </p>
          <p>
            {t('bit4')}
          </p>
          <div className="font__bold mt8 font18">{t('bit6')}</div>
          <p>
          {t('bit7')} 
          </p>
          <p>
          {t('bit8')} 
          </p>
          <div className="font__bold mt8 font18">{t('bit9')}</div>
          <p>
          {t('bit10')} 
          </p>
          <p>
          {t('bit11')}
          </p>
          <p>
          {t('bit12')}
          </p>
          <div className="font__bold mt8 font18">{t('bit13')}</div>
          <p>
          {t('bit14')} 
          </p>
          <p>
          {t('bit15')}
          </p>
        </div>
      </div>
    </>
  );
};
export default Index;
