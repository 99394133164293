import en from "./en.json";
import tc from "./tc.json";

export const resources = {
	"en": {
		translation: en
	},
	"tc": {
		translation: tc
	}
}