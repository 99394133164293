import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import * as echarts from "echarts";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "components/EchartsLine/index.scss";
interface PowerproductsProps {
  damage: string;
  Day: Array<any>;
  Balance: Array<any>;
}

interface EchartsLineProps {
  powerproducts?: PowerproductsProps;
  sliderValue: number;
}
const Index: React.FC<EchartsLineProps> = ({powerproducts, sliderValue}) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const chartDomRef = useRef(null);

  type EChartsOption = echarts.EChartsOption;


  useEffect(() => {
    const chartDom = document.getElementById("EchartsLine");
    const yAxisMax = powerproducts?.Balance[powerproducts?.Balance.length - 1].toString();
    if (chartDom) {
      var myChart = echarts.init(chartDom);
      var option: EChartsOption;

      option = {
        grid: {
          top: 10,
          left: 50,
          bottom: 30,
          width: 'auto',
          height: 'auto'
        },
        legend: {
          data: [t('收益')],
          icon: 'rect',
          itemStyle: {
            color: "rgb(89, 180, 151)"
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: powerproducts?.Day,
        },
        yAxis: {
          axisLabel: {
          },
          type: "value",
          splitLine: {
            show: false,
          },
          min: 0,
          max: yAxisMax
          // data: {}
        },
        series: [
          {
            showSymbol: false,
            name: t('收益'),
            stack: 'Total',
            data: [
              ["1", 0], // 起点
              [ powerproducts?.Day[sliderValue] , powerproducts?.Balance[sliderValue]],  // 目标点
            ],
            type: "line",
            lineStyle: {
              color: "rgb(58, 120, 99)",
            },
            areaStyle: {
              color: "rgb(89, 180, 151)",
            },
            markLine: {
              lineStyle: {
                type: "dotted",
                color: "rgb(274, 148, 28)",
                width: 2,
              },
              silent: true,
              symbol: "none", //去掉箭头
              itemStyle: {},
              data: [
                {
                  label: {
                    position: "insideStartTop", // 表现内容展示的位置
                    formatter: t('收回折損成本'), // 标线展示的内容
                    // color: '#8C8C8C'  // 展示内容颜色
                  },
                  yAxis: powerproducts?.damage,
                },
              ],
            },
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }, [chartDomRef.current, powerproducts, sliderValue]);

  return (
    <>
      <div id="EchartsLine" ref={chartDomRef} className="EchartsLine"></div>
    </>
  );
};
export default Index;
