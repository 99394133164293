import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Toast } from "antd-mobile";
import { t } from "i18next";
import request from "utils/request";
import { localSetItem } from "utils/tools";

interface AuthRouteProps {
  children: any;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const [isCheckToken, setIsCheckToken] = useState<any>();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  useEffect(() => {
    const checkToken = async () => {
      try {
        const params = {
          account: email || address,
          token: token, // 1是注册  2是找回密码
        };
        const res: any = await request({
          method: "POST",
          url: "/api/users/checkToken",
          data: params,
        });
        if (res.code === 0) {
          localSetItem("token");
          localSetItem("email");
          localSetItem("address");
          setIsCheckToken(0);
        } else {
          setIsCheckToken(1);
        }
      } catch (error) {
        setIsCheckToken(0);
        // 处理异常情况，比如显示错误信息
        Toast.show("发生错误，请重试");
        console.error("Error while checking token:", error);
      }
    };

    if (token) {
      checkToken();
    } else {
      setIsCheckToken(0);
    }

  }, [token, email, address]);

  if (!token) {
    Toast.show(t("請先登入錢包！"));
    const search = location.search;
    if (search) {
      return <Navigate to={`/${search}`} replace />;
    }
    return <Navigate to="/" replace />;
  }

  if (isCheckToken === 0) {
    const search = location.search;
    if (search) {
      return <Navigate to={`/${search}`} replace />;
    }
    return <Navigate to="/" replace />;
  }
  if (isCheckToken === 1) {
     // 在这里返回包裹的组件
    return <>{children}</>;
  }
  return <>{children}</>;
 
};

export default AuthRoute;
