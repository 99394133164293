import {ethers, utils} from "ethers";
import {trim} from "./tools";
import {config} from "../config";

const node = config.REACT_APP_NETWORK

// const simpleRpcProvider = new Web3.providers.HttpProvider(node)
//
//
// export const getContract = (abi: any, address?: string | undefined, options?: any) => {
//     let web3 = new Web3(simpleRpcProvider)
//     return new web3.eth.Contract(abi, address, options);
// }

export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider(node)

export const getContract = (address: any, abi: any, library?: any) => {
    let sign = library ? library.getSigner().connectUnchecked() : simpleRpcProvider
    return new ethers.Contract(address, abi, sign)
}

export const deployContract = async (abi: any, bytecode: any, account: any, library?: any) => {
    let sign = library.getSigner()
    const factory = new ethers.ContractFactory(abi, bytecode, sign)
    // console.log(factory.getDeployTransaction())
    try {
        let contract = await factory.deploy();
        console.log(contract.address);
        console.log(contract.deployTransaction.hash);
        // await contract.deployed()
        return contract
    } catch (e) {
        return false
    }
}

export const getBalance_bnb = async (account: any, library?: any) => {
    const b = await library.getBalance(account)
    return trim(b, 18)
}
export const tranfer_bnb = async (from:any, to:any,value:any,library: any)=>{
    try {
        from = utils.getAddress(from)
        to = utils.getAddress(to)
        value = utils.parseEther(value)
        const tx = {
            from: from,
            to: to,
            value: value,
        };
        let res = await library.getSigner().sendTransaction(tx)
        await library.waitForTransaction(res.hash)
        console.log(res.hash)
        return res.hash
    }catch (e) {
        return ''
    }
}

export const getBalance_token = async (account: any, address: any, abi: any, decimal: number, library?: any) => {
    const contract = getContract(address, abi, library)
    return trim(await contract.balanceOf(account), decimal)
}

// export const getMulticallContract = (library?: any) => {
//     let sign = library || simpleRpcProvider
//     let multicall = process.env.REACT_APP_MULTICALL || ""
//     return new ethers.Contract(multicall, multicallAbi, sign)
// }

// export const getToken20Contract = (address: any, library?: any) => {
//     let sign = library ? library.getSigner().connectUnchecked() : simpleRpcProvider
//     return new ethers.Contract(address, Token20, sign)
// }
