import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
  Popover,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import usdtImg from "assets/images/usdtImg.png";
import copyIcon from "assets/images/copyAsh.png";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  ForwardedRef,
  forwardRef,
} from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import QRCode from "qrcode.react";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import rightBlack from "assets/images/rightBlack.png";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";

interface QRCodeProps {
  value: string;
  className: string;
  size: number;
  bgColor: string;
  fgColor: string;
}
const DepositAddress: React.FC = (props: any) => {
  const QRCodeComponent = forwardRef(
    (props: QRCodeProps, ref: ForwardedRef<SVGSVGElement>) => (
      <QRCode {...props} ref={ref} />
    )
  );
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();

  const qrCodeRef = useRef<HTMLDivElement | null>(null);

  const [walletUrl, setWalletUrl] = useState("");
  const [nowNet, setNowNet] = useState("bep");
  const [qrCodeReady, setQrCodeReady] = useState(false);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  const [btnLoadin, setBtnLoadin] = useState<any>(false);


  const getRechargeAd = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/rechargeAd",
        params: { lang: i18n.language },
        data: parmas,
      });
      setWalletUrl(res.data);
    } catch (error) {}
  };
  const getRechargeAdTrc = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/rechargeAdTrc",
        params: { lang: i18n.language },
        data: parmas,
      });
      setWalletUrl(res.data);
    } catch (error) {}
  };

  const downloadQRCode = () => {
    if (!qrCodeReady || !qrCodeRef.current) return;
    const canvas = qrCodeRef.current.querySelector(
      "canvas"
    ) as HTMLCanvasElement;
    const pngUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "qr-code.png";
    link.href = pngUrl;
    link.click();
  };

  const netList = [
    { key: "bep", text: "USDT-BEP20" },
    { key: "trc", text: "USDT-TRC20" },
  ];

  const getNetText = (nowNet: string) => {
    for (let i = 0; i < netList.length; i++) {
        if (netList[i].key === nowNet) {
            return netList[i].text;
        }
    }
    return null; // 如果未找到匹配的项，则返回 null 或适当的默认值
}

  const onChangeNet = (node: any) => {
    setNowNet(node.key)
  }

  const onFinish = async(v: any) => {
    try {
      if (v.toaddress.length <= 0) {
        return;
      }
      if (v.amount <= 0) {
        Toast.show(t('輸入數量不得小於0'));
        return;
      }
      setBtnLoadin(true)
      const parmas = { account: email || address,
        token: token, fromaddress: walletUrl, toaddress: v.toaddress, amount: v.amount};
      const res: any = await request({
        method: "POST",
        url: "/api/users/createRechargeOrder",
        params: {lang: i18n.language},
        data: parmas,
      });
      setBtnLoadin(false)
      Toast.show(res.msg)
    } catch (error) {setBtnLoadin(false)}
  } 

  // useEffect(() => {
  //   getRechargeAd();
  // }, []);
  useEffect(() => {
    if (nowNet === 'bep') {
      getRechargeAd();
    } else if ( nowNet === 'trc') {
      getRechargeAdTrc();
    }
  }, [nowNet]);

  useEffect(() => {
    if (qrCodeRef.current) {
      setQrCodeReady(true);
    }
  }, [qrCodeRef]);

  return (
    <>
      <div className="DepositAddress">
        <Header title={t("儲值")} />
        <div className="DepositAddress__content">
          <div className="card__box">
            <div className="list__left">
              <Image src={usdtImg} width={40} height={40} />{" "}
              <div className="flex__space__between">
                <div className="flex__direction">
                  <span className="USDT">USDT</span> <span>Tether</span>
                </div>
              </div>
            </div>
            <div className="card__box__title">{t("選擇主網")}</div>
            <Popover.Menu
            actions={netList.map((action) => ({
              ...action,
            }))}
            onAction={(node) => onChangeNet(node)}
            placement="bottom-start"
            trigger="click"
          >
            <div className="card__box__input flex__space__between">{getNetText(nowNet)} <Image className="rightBlack" src={rightBlack} width={6} height={12} /></div>
          </Popover.Menu>
          </div>
          <div className="card__box">
            {/* <div ref={qrCodeRef} className="QRCode__box">
              <QRCode
                className="QRCode"
                value={walletUrl || ""}
                size={134} // 二维码图片大小
                bgColor="#fff" // 二维码背景颜色
                fgColor="#000" // 二维码图案颜色
              />
            </div> */}
            <div className="card__box__input">
              <div>{t("儲值地址")}</div>
              <span className="url__qrcode">
                {walletUrl || ""}
                <Button
                  onClick={() => {
                    walletUrl && copy(walletUrl);
                    Toast.show(t("複製成功"));
                  }}
                >
                  <Image src={copyIcon} width={20} height={20} />
                </Button>
              </span>
            </div>
            <Form
          onFinish={onFinish}
          footer={
            <>
            <Button
                loading={btnLoadin}
                style={{
                  "--background-color": "rgb(36, 105, 82)",
                  "--border-radius": "40px",
                  "--text-color": "#fff",
                }}
                block
                type="submit"
                size="large"
              >
                {t("我已儲值")}
              </Button>
            </>
          }
          layout="horizontal"
        >
          <div className="card__box__title">{t('儲值金額')}</div>
          <Form.Item
            name={"amount"}
            extra={
              <div className={"form__USDT"}>
                {" "}
                <span>USDT</span>{" "}
              </div>
            }
          >
            <Input />
          </Form.Item>
          <div className="card__box__title">{t('交易哈希')}</div>
          <Form.Item
            name={"toaddress"}
          >
            <Input />
          </Form.Item>
        </Form>
            {/* <div
              onClick={() => downloadQRCode()}
              className="qrcode__btn save__btn"
            >
              {t("保存二維碼")}
            </div> */}
            
          </div>
        </div>
      </div>
    </>
  );
};
export default DepositAddress;
