import {InjectedConnector} from '@web3-react/injected-connector'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'
import {ethers} from 'ethers'
// import Web3 from "web3";
import sample from 'lodash/sample'
import {config} from "../config";

interface ConnectorNames {
    "Metamask": any,
    "WalletConnect": any,

    [propName: string]: any
}

const POLLING_INTERVAL = 12000

let chainId = Number(config.REACT_APP_CHAINID)
const nodes: any = [config.REACT_APP_NETWORK]
export const injected = new InjectedConnector({supportedChainIds: [chainId]})

export const walletconnect = new WalletConnectConnector({
    supportedChainIds: [chainId],
    rpc: {[chainId]: sample(nodes)},
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
})

export const getLibrary = (provider: any)=> {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL
    return library
    // return new Web3()
}

export const connectorsByName: ConnectorNames = {
    "Metamask": injected,
    "WalletConnect": walletconnect
}
