import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
  Checkbox,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, localSetItem } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
import { FormInstance } from "antd-mobile/es/components/form";
import { throttle } from "lodash";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const formRef = React.createRef<FormInstance>();
  const navigate = useNavigate();
  const goToPage = useNavitateLink();
  const location = useLocation();
  const [checkValue, setCheckValue] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isRunning, setIsRunning] = useState(false);
  const onFinish = async (v: {
    address: any;
    invitationaddress: any;
    email: any;
    password: any;
    code: any;
  }) => {
    try {
      if (!checkValue) {
        return;
      }
      localSetItem('token');
      localSetItem('email');
      localSetItem('address');
      const parmas = {
        email: v.email,
        password: v.password,
        address: v.address,
        invitationaddress: v.invitationaddress,
        code: v.code,
      };
      const res: any = await request({
        method: "POST",
        url: "/api/users/emailRegister",
        params: { lang: i18n.language },
        data: parmas,
      });
      Toast.show(res?.msg);
      if (res.code === 1) {
        goToPage('/')
      }
    } catch (error) {}
  };
  const sendCaptcha = async () => {
    try {
      const email = formRef?.current?.getFieldValue("email");
      if (email) {
        startCountdown()
        const parmas = {
          email: email,
          eventtype: "1", // 1是注册  2是找回密码
        };
        const res: any = await request({
          method: "POST",
          url: "/api/users/sendCaptcha",
          params: { lang: i18n.language },
          data: parmas,
        });
        return;
      }
      // Toast.show(res?.msg);
    } catch (error) {}
  };
  // goToPage("/wallet");

  const validateMessages = {
    // required: `${t('請輸入')}${name}`,
    required: t("請輸入") + "${name}",
    // ...
  };

  const ThrottledOnClickNodeBuy = throttle(sendCaptcha, 3000, {
    leading: true,
    trailing: false,
  });

  const startCountdown = () => {
    setIsRunning(true);
    setTimeLeft(60);
  };

  useEffect(() => {
    const search = location.search;
    let referad = "";
    if (search) {
      const match = /invitationCode=([^&]+)/.exec(search);
      if (match) {
        referad = match[1];
      } else {
        console.log("invitationCode 未找到");
      }
      formRef.current?.setFieldValue('invitationaddress', referad)
    }
  }, []);

  useEffect(() => {
    let countdownInterval: string | number | NodeJS.Timeout | undefined;

    if (isRunning) {
      countdownInterval = setInterval(() => {
        setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isRunning]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsRunning(false);
      setTimeLeft(60);
    }
  }, [timeLeft])

  return (
    <>
      <div className="Login">
        <div className="font24 font__bold paddingl16">{t('邮箱註冊')}</div>
        <Form
          ref={formRef}
          name="form"
          mode="card"
          // layout='horizontal'
          onFinish={onFinish}
          validateMessages={validateMessages}
          footer={
            <Button
              className="green-color border__r50"
              block
              type="submit"
              color="primary"
              size="large"
            >
              {t('提交')}
            </Button>
          }
        >
          <Form.Item name="email" label={t('邮箱')} rules={[{ required: true, type: 'email' }]}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Header />
          <Form.Item
            name="password"
            label={t('密碼')}
            rules={[{ required: true }]}
          >
            <Input placeholder="" />
          </Form.Item>
          <div className="Password__font">
            {t('密碼長度為6個字符，包含數字')}
          </div>
          <Form.Item
            name="address"
            label={t('地址')}
            rules={[{ required: true }]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="invitationaddress"
            label={t('邀請地址')}
            rules={[{ required: true }]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            className="code__box"
            name="code"
            label={t('驗證碼')}
            extra={
              <div className="">
                <div className="password__top"></div>
                <Button
                  disabled={isRunning}
                  onClick={() => ThrottledOnClickNodeBuy()}
                  className="send__btn"
                  fill="none"
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="send__shu"
                      style={{
                        display: "inline-block",
                        width: "1px",
                        height: "40px",
                        color: "black",
                        background: "black",
                      }}
                    ></span>
                    <span style={{ display: "inline-block", margin: "0 20px" }}>
                      {isRunning ? timeLeft : t('發送')}
                    </span>
                  </span>
                </Button>
              </div>
            }
            rules={[{ required: true }]}
          >
            <Input className="send__input" placeholder="" />
          </Form.Item>
          {/* <input type="checkbox" value={checkValue} id="" /> */}
          <Checkbox checked={checkValue} onChange={(v) => setCheckValue(v)}>
            {t('同意《使用者協議》和《隱私權政策》')}
          </Checkbox>
        </Form>
        <div className="flex__space__center">
          {t('已經註冊？')}{" "}
          <span
            onClick={() => goToPage("/login")}
            className="ml8"
            style={{ color: "royalblue" }}
          >
            {" "}
            {t('現在登入')}{" "}
          </span>
        </div>
      </div>
    </>
  );
};
export default Index;
