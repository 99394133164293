import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, localSetItem } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const navigate = useNavigate();
  const goToPage = useNavitateLink();
  const onFinish = async (v: { email: any; password: any }) => {
    try {
      localSetItem('token');
      localSetItem('email');
      localSetItem('address');
      const parmas = { email: v.email, password: v.password };
      const res: any = await request({
        method: "POST",
        url: "/api/users/login",
        params: { lang: i18n.language },
        data: parmas,
      });
      Toast.show(res?.msg);
      if (res.code === 1) {
        localSetItem('token', res.data);
        localSetItem('email', v.email);
        goToPage('/');
      }
    } catch (error) {}
  };
  // goToPage("/wallet");

  const validateMessages = {
    // required: `${t('請輸入')}${name}`,
    required: t('請輸入') + "${name}",
    // ...
  };
  useEffect(() => {}, []);

  return (
    <>
      <div className="Login">
        <div className="font24 font__bold paddingl16">{t('你好，')}</div>
        <div className="font24 font__bold paddingl16">{t('請登入')}</div>
        <Form
          name="form"
          mode="card"
          onFinish={onFinish}
          validateMessages={validateMessages}
          footer={
            <Button
              className="green-color border__r50"
              block
              type="submit"
              color="primary"
              size="large"
            >
              {t('提交')}
            </Button>
          }
        >
          <Form.Item
            name="email"
            label={t('邮箱')}
            rules={[{ required: true, type: 'email' }]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Header />
          <Form.Item
            name="password"
            label={t('密碼')}
            rules={[{ required: true }]}
          >
            <Input placeholder="" />
          </Form.Item>
          <div onClick={() => goToPage('/forgotPassword')} className="forgotLink">{t('忘記密碼？')}</div>
        </Form>
        <div className="flex__space__center">
          {t('還沒有帳戶？')}{" "}
          <span onClick={() => goToPage('/registration')} className="ml8" style={{ color: "royalblue" }}>
            {" "}
            {t('立即註冊')}{" "}
          </span>
        </div>
      </div>
    </>
  );
};
export default Index;
