import { utils } from "ethers";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js/core";
import { Modal, Image } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import modal1 from "assets/images/modal1.png"
import modal2 from "assets/images/modal2.png"
import modal3 from "assets/images/modal3.png"
import modal4 from "assets/images/modal4.png"
import { t } from "i18next";

export const addressSub = (
  string: string,
  before: number,
  after: number = before,
  padding: string = "..."
) => {
  if (!string) return;
  return (
    string.substring(0, before) +
    padding +
    string.substring(string.length - after, string.length)
  );
};

export const trim = (hex: any, decimal: number, retain: number = 4) => {
  if (decimal % 3 > 0) {
    hex = (BigInt(hex) / BigInt(10 ** decimal)).toString(10);
  } else if (decimal !== 0) {
    hex = utils.formatUnits(hex, decimal);
  }
  let isMinus = false; //是不是负数
  let tem = hex.toString();

  let minus = tem.indexOf("-");
  if (minus === 0) {
    tem = utils.formatUnits(tem.slice(1), decimal);
    isMinus = true;
  }

  let res = tem.indexOf(".");
  if (res >= 0) {
    let left = tem.length - 1 - res;
    left > retain && (tem = tem.substring(0, res + 1 + retain));
  }

  if (res < 0) {
    res = tem.length;
    tem += ".";
  }
  while (tem.length <= res + retain) {
    tem += "0";
  }

  if (isMinus) {
    tem = "-" + tem;
  }

  return tem;
};

export const toWei = (hex: any, decimal: number) => {
  hex = hex.toString();
  return utils.parseUnits(hex, decimal);
};
export const toNum = (hex: any, decimal: any = 18) => {
  // hex = BigNumber.from(hex)
  // decimal = BigNumber.from(decimal)
  // let ten = BigNumber.from(10);
  // return hex.div(ten.pow(decimal)).toString()
  return String(Number((parseInt(hex) / 10 ** parseInt(decimal)).toFixed(4)));
};

export function parseTime(time: any, cFormat?: any) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time);
      } else {
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  return format.replace(/{([ymdhisa])+}/g, (result: any, key: any) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
}

export function countdown(leftTime: any) {
  if (leftTime >= 0) {
    const d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
    let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
    const m = Math.floor((leftTime / 1000 / 60) % 60);
    const s = Math.floor((leftTime / 1000) % 60);
    // console.log(d,h,m,s)
    h = d * 24 + h;
    return formatNumber(h) + ":" + formatNumber(m) + ":" + formatNumber(s);
  } else {
    return "";
  }
}

function formatNumber(n: any) {
  n = n.toString();
  return n[1] ? n : "0" + n;
}

export function sort(list: any, path: any, parameter: any) {
  return list.sort(function (a: any, b: any) {
    if (path) return a[parameter] - b[parameter]; //从小到大
    return b[parameter] - a[parameter]; //从大到小
  });
}

export function base64_encode(str: string) {
  return Base64.stringify(CryptoJS.enc.Utf8.parse(str));
}

export function base64_decode(str: string) {
  return CryptoJS.enc.Utf8.stringify(Base64.parse(str));
}
export function modalComponents(title: string, content: string) {
  const modalContent = (
    <>
      <div>
        <div className="break__spaces">{content}</div>
        <div
          onClick={() => {
            Modal.clear();
          }}
          className="modal__ok"
        >
          {t('我已知曉')}
        </div>
      </div>
    </>
  );
  Modal.show({
    title: title,
    content: modalContent,
    closeOnMaskClick: true,
  });
}
export function modalDefiComponents(data: { a: any; b: any; c: any; d: any; }) {
  const modalContent = (
    <>
      <div>
        <div className="aling__center">
          <Image src={modal1} width={20} height={20} className="mr4" />
          <span className="font16 font__bold">{t('項目方名')}</span>
        </div>
        <div className="m80">
          {data.a}
        </div>
        <div className="aling__center">
          <Image src={modal2} width={20} height={20} className="mr4" />
          <span className="font16 font__bold">{t('運行時間')}</span>
        </div>
        <div className="m80">
          {data.b}
        </div>
        <div className="aling__center">
          <Image src={modal3} width={20} height={20} className="mr4" />
          <span className="font16 font__bold">{t('風險評估')}</span>
        </div>
        <div className="m80">
          {data.c}
        </div>
        <div className="aling__center">
          <Image src={modal4} width={20} height={20} className="mr4" />
          <span className="font16 font__bold">{t('合約審計報告')}</span>
        </div>
        <div className="m80">
          <a href={data.d}>{data.d}</a> 
        </div>
        <div
          onClick={() => {
            Modal.clear();
          }}
          className="modal__ok"
        >
          {t('確定')}
        </div>
      </div>
    </>
  );
  Modal.show({
    content: modalContent,
    closeOnMaskClick: true,
  });
}


// 重写localStorage.setItem
// 可监听local变化
export const localSetItem = (key: string, newValue?: string): void => {
  const originalSetItem = localStorage.setItem;
  const setItemEvent = new Event("setItemEvent") as any;
  setItemEvent.newValue = newValue || '';
  window.dispatchEvent(setItemEvent);
  originalSetItem.call(localStorage, key, newValue || '');
};



