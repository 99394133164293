import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Popup,
  ProgressBar,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, modalComponents } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import markIcon from "assets/images/markIcon.png";
import copyIcon from "assets/images/copyIcon.png";
import rightBlack from "assets/images/rightBlack.png";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const navigate = useNavigate();
  const goToPage = useNavitateLink();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");


  const [visible1, setVisible1] = useState(false);
  const [invitePage, setInvitePage] = useState<any>({});

  const mockContent = (
    <>
      <div>
        <div
          className="flex__space__between font__bold font20 padding20"
          style={{ borderBottom: "1px solid rgba(5, 5, 5, 0.06)" }}
        >
          <div>{t('邀請等級體系')}</div>
          <div>X</div>
        </div>
        <div className="padding016">
          <div className="font16 font136-color">
            {t('用戶達成指定的邀請任務，提升邀請等級，即可享受額外的比例的團隊收益獎勵！')}
          </div>
          <table className="inviteTable">
            <thead>
              <tr>
                <th className="border-top-left-radius">{t('等級')}</th>
                <th>{t('個人投資要求')}</th>
                <th>{t('團隊要求')}</th>
                <th className="border-top-right-radius">{t('額外收益')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="s1">V1</th>
                <th>500USDT</th>
                <th>
                  {t('團隊投資')}{'>=1wU'}
                  <br />
                  {t('小區投資')}{'>=5kU'}
                </th>
                <th>+10%</th>
              </tr>
              <tr>
                <th className="s2">V2</th>
                <th>1000USDT</th>
                <th>
                {t('團隊投資')}{'>=5wU'}
                  <br />
                  {t('小區投資')}{'>=2.5wU'}
                </th>
                <th>+20%</th>
              </tr>
              <tr>
                <th className="s3">V3</th>
                <th>2000USDT</th>
                <th>
                {t('團隊投資')}{'>=15wU'}
                  <br />
                 {t('小區投資')} {'>=7.5wU'}
                </th>
                <th>+30%</th>
              </tr>
              <tr>
                <th className="s4">V4</th>
                <th>5000USDT</th>
                <th>
                  {t('團隊投資')}{'>=50wU'}
                  <br />
                  {t('小區投資')}{'>=25wU'}
                </th>
                <th>+40%</th>
              </tr>
              <tr>
                <th className="s5">V5</th>
                <th>10000USDT</th>
                <th>
                  {t('團隊投資')}{'>=150wU'}
                  <br />
                  {t('小區投資')}{'>=75wU'}
                </th>
                <th>+50%</th>
              </tr>
              <tr>
                <th className="s6">V6</th>
                <th>20000USDT</th>
                <th>
                  {t('團隊投資')}{'>=500wU'}
                  <br />
                  {t('小區投資')}{'>=250wU'}
                </th>
                <th>+60%</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  // /api/index/invitePage

  const getInvitePage =async () => {
    try {
      const parmas = { account: email || address,
        token: token,};
      const res = await request({
        method: 'POST',
        url: '/api/index/invitePage',
        params: {lang: i18n.language},
        data: parmas
      })
      setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);

    } catch (error) {
      
    }
  }

  const calculatePercentage = (currentValueStr: string, totalValueStr: string) => {
      // 将字符串类型的数字转换为数值类型
    const currentValue = parseFloat(currentValueStr);
    const totalValue = parseFloat(totalValueStr);

    if (currentValue >= totalValue) {
      return 100;
    }
    
    return Math.floor((currentValue / totalValue) * 100); // 使用 Math.floor() 向下取整
  }

  useEffect(() => {
    getInvitePage()
  }, [i18n.language]);

  return (
    <>
      <div className="InviteFriend">
        <Header title={t('邀請好友')} />
        <div className="InviteFriend__topbox">
          <div className="top__title">
            <span>{t('我邀請的團隊')}</span>
            <Image
              onClick={() => setVisible1(true)}
              src={markIcon}
              width={20}
              height={20}
            />
          </div>
          <div className="now__lev">
            <div className="now__lev__title">{t('當前個人等級')}</div>
            <div className="now__lev__rule">
              <span className="now__rule__lev">{invitePage?.level}</span>
              <span className="now__rule__rule">+{invitePage?.additionalbenefits}%{t('額外收益')}</span>
              <span className="now__rule__next">( {t('下一等級')}+{invitePage?.nextadditionalbenefits}%)</span>
            </div>
          </div>
          <div className="next__lev">
            <div className="now__lev__title">{t('下個等級要求')}</div>
            <div className="next__lev__box">
              <div className="next__lev__box flex__space__between">
                <div className="next__lev__title">{t('個人投資')}</div>
                <div className="next__lev__num">
                  <span>{invitePage?.total} / {invitePage?.needtotal}</span>
                  <span className="next__lev__usdt">USDT</span>
                  <Image onClick={() => modalComponents(t("個人算力"),t("即用戶當前累計租賃的算力總價值"))} src={markIcon} width={20} height={20} />
                </div>
              </div>
              <div>
                <ProgressBar
                  percent={calculatePercentage(invitePage?.total, invitePage?.needtotal)}
                  style={{
                    "--track-width": "4px",
                  }}
                />
              </div>
            </div>
            <div className="next__lev__box">
              <div className="next__lev__box flex__space__between">
                <div className="next__lev__title">{t('小區業績')}</div>
                <div className="next__lev__num">
                  <span>{invitePage?.smalltotal} / {invitePage?.needsmalltotal}</span>
                  <span className="next__lev__usdt">USDT</span>
                  <Image onClick={() => modalComponents(t("團隊小區總算力"),t("用戶邀請團隊中，除了大區分支外，其他所有分支承租的算力總和"))} src={markIcon} width={20} height={20} />
                </div>
              </div>
              <div>
                <ProgressBar
                  percent={calculatePercentage(invitePage?.smalltotal, invitePage?.needsmalltotal)}
                  style={{
                    "--track-width": "4px",
                  }}
                />
              </div>
            </div>
            <div className="next__lev__box">
              <div className="next__lev__box flex__space__between">
                <div className="next__lev__title">{t('團隊总業績')}</div>
                <div className="next__lev__num">
                  <span>{invitePage?.teamtotal} / {invitePage?.needteamtotal}</span>
                  <span className="next__lev__usdt">USDT</span>
                  <Image onClick={() => modalComponents(t("團隊总業績"),t("用戶邀請的團隊中，所有成員當前租賃的算力總和"))} src={markIcon} width={20} height={20} />
                </div>
              </div>
              <div>
                <ProgressBar
                  percent={calculatePercentage(invitePage?.teamtotal, invitePage?.needteamtotal)}
                  style={{
                    "--track-width": "4px",
                  }}
                />
              </div>
            </div>
            <div className="next__lev__box">
              <div className="next__lev__box flex__space__between">
                <div className="next__lev__title">{t('大區業績')}</div>
                <div className="next__lev__num">
                  <span>{invitePage?.bigtotal}</span>
                  <span className="next__lev__usdt">USDT</span>
                  <Image onClick={() => modalComponents(t("團隊大區算力"),t("用戶邀請的團隊中，承租總算力最高的團隊分支所有算力總和"))} src={markIcon} width={20} height={20} />
                </div>
              </div>
            </div>
          </div>
          <div onClick={() => goToPage("/teamDetail")} className="look__team">
            {t('查看團隊詳情')}
          </div>
        </div>
        <div className="InviteFriend__botbox">
          <div className="myIncome card__box padding20">
            <div className="myIncome__title">{t('我的邀請收益')}</div>
            <div className="myIncome__box">
              <div className="myIncome__box__title flex__direction">
                <div onClick={() => goToPage("/allAward")}>
                  {t('邀請總獎勵')}{" "}
                  <Image className="rightBlack" src={rightBlack} width={6} />
                </div>
                <div className="myIncome__usdt">{invitePage?.inviterewards} USDT</div>
              </div>
              <div className="myIncome__box__title flex__direction">
                <div>{t('昨日獎勵')}</div>
                <div className="myIncome__usdt">{invitePage?.yesterdayrewards} USDT</div>
              </div>
            </div>
            <div className="myIncome__box">
              <div onClick={() => goToPage("/inviteAllAward")} className="myIncome__bottom flex__direction">
                <div className="bottom__font">{t('邀請獎勵')}</div>
                <div className="myIncome__usdt">{invitePage?.directrewards} USDT</div>
              </div>
              <div onClick={() => goToPage("/teamAward")} className="myIncome__bottom flex__direction">
                <div className="bottom__font">{t('團隊獎勵')}</div>
                <div className="myIncome__usdt">{invitePage?.teamrewards} USDT</div>
              </div>
            </div>
          </div>
          <div className="myIncome card__box padding20">
            <div className="myIncome__title">{t('我的邀請鏈接')}</div>
            <div className="myIncome__link">
              <div className="myIncome__link__title">{t('連結地址')}</div>
              <div className="flex__space__between">
                <div className="myIncome__link__title">
                  {addressSub(window.location.origin + window.location.pathname +  + '?invitationCode=' + invitePage?.address, 10, 15)}
                </div>
                <Button onClick={() => { copy(window.location.origin + window.location.pathname + '?invitationCode=' + invitePage?.address); Toast.show(t('操作成功'))}}>
                  <Image src={copyIcon} width={20} height={20} />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Popup
          visible={visible1}
          onMaskClick={() => {
            setVisible1(false);
          }}
          onClose={() => {
            setVisible1(false);
          }}
          bodyStyle={{
            height: "550px",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          {mockContent}
        </Popup>
      </div>
    </>
  );
};
export default Index;
